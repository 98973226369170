/* A fix for an issue I noticed in Safari.
I reported the issue: https://github.com/neptunian/react-photo-gallery/issues/174
Keep an eye on the ticket and maybe one day I can remove this */
.react-photo-gallery--gallery>div>img {
  align-self: flex-start;
}

.modal-close-button {
  position: absolute;
  z-index: 100;
  right: 15px;
  top: 15px;
}

.gallery-image{
  width: "100%";
  display: "block";
  cursor: pointer;
}

.footer {
  padding: 3rem 1.5rem 6rem;
  margin-top: 3rem;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #000;
}

.swiper-slide img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}

.swiper-slide video {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}


.swiper {
  width: 100%;
  height: 100%;
}

.ReactModal__Content {
  inset: 10px;
  padding: 10px;
}

.ReactModal__Content--after-open {
  inset: 10px;
  padding: 10px;
}